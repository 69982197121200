<script>
  import Layout from "../../layouts/main";
  import test from "@/app.config.json";
  import axios from "axios";
  import moment from "moment";
  import DatePicker from "vue2-datepicker";
  import 'vue2-datepicker/index.css';
  import Multiselect from 'vue-multiselect'

  //calendar///////
    import FullCalendar from "@fullcalendar/vue";
    import dayGridPlugin from "@fullcalendar/daygrid";
    import timeGridPlugin from "@fullcalendar/timegrid";
    import interactionPlugin from "@fullcalendar/interaction";
    import bootstrapPlugin from "@fullcalendar/bootstrap";
    import listPlugin from "@fullcalendar/list";
///////////////////////////////////////////////////

  export default {
    page: {title: "EINSTUDIO-Meeting-List",meta: [{name: "description", content:"sdfasdfafd"}]},
    components: {
      Layout,FullCalendar,DatePicker,Multiselect
    },data() {
      return {
        title: "MEETINGS",
        items: [{text: "Home"}, {text: "Meetings",active: true}],
        baseUrl:test.baseUrl,user:[],applicationTypeId:"",listData:[],name:"",explanation:"",
        recordId:"",typeData:[],listGroup:[],defaultdate: "",showModal: false,starti:null,endi:null,isInGroup:false,
        calendarOptions: {
            headerToolbar: {
            left: "prev,next today",
            center: "title",
            //right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
            right: "timeGridWeek,timeGridDay",
            },
            plugins: [
            dayGridPlugin,
            timeGridPlugin,
            interactionPlugin,
            bootstrapPlugin,
            listPlugin,
            ],
            initialView: "timeGridWeek",
            slotLabelFormat: { hour: 'numeric', minute: '2-digit', omitZeroMinute: false, hour12: false },
        //  slotDuration: "00:30:00",
        //  slotLabelInterval: "00:30:00",
            nextDayThreshold: '09:00:00',
            themeSystem: "bootstrap",
            select:this.timeSelected,
            //editable: true,
            //droppable: true,
            eventResizableFromStart: true,
            weekends: true,
           // selectable:(this.user.rol=='teacher'?true:false),
            selectMirror: true,
            dayMaxEvents: false,
           // initialEvents:this.listData  //[{title:"sdff",start:"2022-12-18 08:00",end:"2022-12-18 10:00"}]
           //eventSources: [{url: test.baseUrl+"/Applications/GetAll?status=true&pagesize=0&pageindex=0"}]
           events:[]
        },
        groupIds: []
      };
    },created:function(){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.loadData();
      if(this.user.rol=='admin' || this.user.rol=='teacher')
      {
        this.calendarOptions.selectable=true;
        this.loadTypeData();
      }
      else{
        this.calendarOptions.selectable=false;
      }
    },methods: {
          addData(){
            let loader= this.$loading.show();
            var jsonObject={
                status:true,
                isInGroup:this.isInGroup,
                userId:this.user.id,
                groupIds:this.groupIds,
                applicationTypeId:this.applicationTypeId,
                applicationStartDate:this.starti,
                applicationEndDate:this.endi,
                name:this.name,
                explanation:this.explanation
            }
            axios.post(`${this.baseUrl}/Applications/Add`,jsonObject, 
            { headers: {  Authorization: `Bearer ${this.user.jwt}`, "Content-Type": "application/json"}}).then((response) => 
            {
                this.groupIds=[];
                loader.hide();
                this.$swal("",response.data.message, "success");
                this.hideModal('modal-lg');     
                this.loadData();
                // this.$nextTick(() => 
                // {
                //  this.$router.push('/applicationlist');
                // });
                //this.$router.go("/applicationlist")
            }).catch((error) => 
            {
                loader.hide();
                var html="";
                if(error.response.data.length>0)
                {
                for (let index = 0; index < error.response.data.length; index++) 
                {
                    html +="<small>"+ error.response.data[index].message+"</small><hr>";
                }
                }
                else
                {
                html ="<p class='small'>"+ error.response.data.message+"</p>";
                }
                this.$swal("",html,"error");
            });
        },editData(){

            var stdstr= this.starti.split(' ')[0]
            var stdhrs= this.starti.split(' ')[1]
            var stestr= this.endi.split(' ')[0]
            var stehrs= this.endi.split(' ')[1]

            let loader = this.$loading.show();
            var jsonObject={
                status:true,
                isInGroup:this.isInGroup,
                userId:this.user.id,
                groupIds:this.groupIds,
                applicationTypeId:this.applicationTypeId,
                applicationStartDate: (stdstr.split('.')[2]+'-'+stdstr.split('.')[1]+'-'+stdstr.split('.')[0]+'T'+stdhrs.split(':')[0]+':'+stdhrs.split(':')[1]),
                applicationEndDate:   (stestr.split('.')[2]+'-'+stestr.split('.')[1]+'-'+stestr.split('.')[0]+'T'+stehrs.split(':')[0]+':'+stehrs.split(':')[1]),
                name:this.name,
                explanation:this.explanation,
                id:this.recordId
            }

            axios.put(`${this.baseUrl}/Applications/Set`, jsonObject, {
            headers: { Authorization: `Bearer ${this.user.jwt}`,"Content-Type": "application/json"}
            })
            .then((response) => 
            {
                this.groupIds=[];
                loader.hide();
                this.$swal("",response.data.message, "success");
                this.hideModal('modal-edit');
                this.loadData();
            })
            .catch((error) => 
            {
                loader.hide();
                var html="";
                if(error.response.data.length>0)
                {
                for (let index = 0; index < error.response.data.length; index++) 
                {
                    html +="<small>"+ error.response.data[index].message+"</small><hr>";
                }
                }
                else
                {
                html ="<p class='small'>"+ error.response.data.message+"</p>";
                }
                this.$swal("",html,"error");
            });
        },deleteData(id){
            let loader= this.$loading.show();
            axios.delete(`${this.baseUrl}/Applications/Delete/`+id, {
                headers: { Authorization: `Bearer ${this.user.jwt}`}
            }).then((response) => 
            {
            this.groupIds=[];
             loader.hide();
             this.$swal("",response.data.message, "success");
             this.loadData();
             //this.$router.go("/applicationlist")
            })
            .catch((error) => 
            {
            loader.hide();
            var html ="<p class='small'>"+ error.response.data.message+"</p>";
            this.$swal("",html,"error");
            });
        },loadData(){
            // let loader= this.$loading.show();
            // axios.get(this.baseUrl+"/Applications/GetAll?status=true&pagesize=0&pageindex=0").then((response)=>{
            //     this.calendarOptions.events = response.data;
            //     loader.hide();
            // })
        
            let loader= this.$loading.show();
            let url=this.baseUrl+"/Applications/GetAll?status=true&pagesize=0&pageindex=0"
            if(this.user.rol!='admin')
            url+="&userId="+this.user.id

            axios.get(url,{
                headers:{
                    Authorization: `Bearer ${this.user.jwt}`,
                }
            }).then((response)=>{
                loader.hide();
                this.calendarOptions.events = response.data;
            }).catch((error)=>{
                loader.hide();
                console.log(error)
               // const html ="<small>"+ error.response.data.message+"</small>";
               // this.$swal("",html,"error");
            })
        },loadTypeData(){
            let loader= this.$loading.show();
            axios.get(this.baseUrl+"/Applications/ApplicationType/GetAll?status=true&pagesize=0&pageindex=0",{
                headers:{
                    Authorization: `Bearer ${this.user.jwt}`,
                }
            }).then((response)=>{
                loader.hide();
                this.typeData= response.data;
            }).catch((error)=>{
                loader.hide();
                const html ="<small>"+ error.response.data.message+"</small>";
                this.$swal("",html,"error");
            
            })
        },editInitialData(id){
            axios.get(this.baseUrl+"/Applications/GetOne/"+id,{
                headers:{Authorization: `Bearer ${this.user.jwt}`}
            }).then((response)=>{
                this.name=response.data.name;
                this.explanation=response.data.explanation;
                this.recordId=response.data.id;
                this.starti= moment(response.data.applicationStartDate).format('DD.MM.YYYY HH:mm');
                this.endi=moment(response.data.applicationEndDate).format('DD.MM.YYYY HH:mm');
                this.applicationTypeId= response.data.applicationTypeId;
                this.isInGroup=response.data.isInGroup;

                this.groupIds=[];

                if(response.data.applicationGroups.length>0)
                {
                  for (let index = 0; index < response.data.applicationGroups.length; index++) 
                  {
                    const element = response.data.applicationGroups[index];
                    if(this.groupIds.filter(x=>x.id!=element.groupId)){
                        this.groupIds.push({id:element.groupId,name:element.group.name})
                    }
                  }
                }
                if(this.isInGroup)
                {
                    this.loadGroups();
                    document.getElementById("group-select").classList.remove("d-none")
                }
                else
                document.getElementById("group-select").classList.add("d-none")

            }).catch((error)=>{
            const html ="<small>"+ error.response.data.message+"</small>";
            this.$swal("",html,"error");
            })
        },DeleteConfirm(id){
            this.$swal({ 
            title: "WARNING",
            text: "Are you sure? This data will be delete.",
            //type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Yes",
            cancelButtonText:"No",
            }).then((result) => {
                if (result.value) 
                {
                this.deleteData(id)
                }
            });
        },notBeforeToday(date) {
         return date < new Date(new Date().setHours(0, 0, 0, 0));
        },timeSelected(dates)
        {

            if(moment(dates.start).isBefore(moment())) 
            {
                //$('#calendar').fullCalendar('unselect');22
                this.$swal("","You cannot add an event to a past date.</br> Add after <b>" +moment().format("DD.MM.YYYY HH:mm")+'</b>',"error");
                return false;
            }
            this.starti=moment(dates.start).format('YYYY-MM-DDTHH:mm');
            this.endi= moment(dates.end).format('YYYY-MM-DDTHH:mm');
            this.showModal = true;
        },isInGroupCheck:function()
        {
        let check=document.getElementById("isInGroup");
          if(check.checked){
            this.loadGroups();
            document.getElementById("group-select").classList.remove("d-none")
          }
          else
          {
            this.listGroup=[];
            document.getElementById("group-select").classList.add("d-none")
          }
         
        },loadGroups(){
            let loader= this.$loading.show();
            axios.get(this.baseUrl+"/Group/GetAllSelectBox?status=true&pagesize=0&pageindex=0",{
                headers:{
                    Authorization: `Bearer ${this.user.jwt}`,
                }
            }).then((response)=>{
                loader.hide();
                this.listGroup= response.data;
            }).catch((error)=>{
                loader.hide();
                const html ="<small>"+ error.response.data.message+"</small>";
                this.$swal("",html,"error");
            
            })
        },formatDate(date){
            date= moment(date).format('HH:mm');
            return date;
        },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
    }
  };
</script>
<template>
  <Layout>
    <!-- <div class="row mb-3" v-if="user.rol=='admin'">
        <div class="col-lg-12">
            <b-button v-b-modal.modal-lg variant="primary" class="btn btn-success"><i class="fa fa-calendar"></i> + ADD NEW MEETING</b-button>
        </div>
    </div> -->
    <div class="row">
        <div class="col-sm-12">
            <div class="card">
          <div class="card-body">
            <div class="app-calendar">
                <FullCalendar ref="fullCalendar" :options="calendarOptions" :slotLabelFormat="{ 'hour12': false, 'hour': '2-digit', 'minute': '2-digit'}">
                    <template #eventContent="arg">
                        <b-tooltip :target="arg.event.id" placement="top" class="tooltip-inner">
                            <div class="card bg-info">
                                <div class="card-header text-dark">
                                    <strong>{{arg.event.title}}</strong>
                                </div>
                                <div class="card-body">
                                    <ul class="inner-item text-dark">
                                        <li> <i class="fa fa-user"></i>&nbsp; <span>{{arg.event.extendedProps.userName}}</span></li>
                                        <li> <i class="fa fa-layer-group"></i>&nbsp;<span> {{arg.event.extendedProps.groupName}}</span> </li>
                                        <li> <span><a :href="arg.event.extendedProps.applicationLink" target="_blank"><i class="fa fa-link"></i>&nbsp;Application Link</a></span></li>
                                        <li><span class="small">{{ arg.event.extendedProps.explanation }}</span></li>
                                    </ul>
                                </div>
                                <div class="card-footer" id="actions" v-if="user.rol=='admin' | user.id==arg.event.extendedProps.userid">
                                    <a type="button" class="btn btn-sm btn-danger mr-1" @click="DeleteConfirm(arg.event.id)"><i class="fa fa-trash"></i></a>
                                    <a type="button" v-b-modal.modal-edit class="btn btn-sm btn-warning" @click="editInitialData(arg.event.id)"><i class="fa fa-edit"></i></a>
                                </div>
                            </div>
                        </b-tooltip>
                        <span  :id="arg.event.id"><i class="fa fa-clock"></i> {{formatDate(arg.event.start)+' '+formatDate(arg.event.end)}}</span>
                    </template>
                </FullCalendar>
            </div>
          </div>
        </div>
        </div>
    </div>
    <b-modal id="modal-lg" v-model="showModal" size="lg" title="New Meeting" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-group-form">
            <div class="row">
                <div class="col-12">
                    <i class="fa fa-info-circle"></i>  If you select this, you arrange a meeting for designated group if not you arrange a meeting with all groups.
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4">
                    <div class="form-check">
                       <input class="form-check-input" type="checkbox" v-model="isInGroup"  value="" id="isInGroup" @change="isInGroupCheck()" >
                     <label class="form-check-label" for="isInGroup"> Group meeting? </label>
                   </div>
                </div>
                <div class="col-sm-8 d-none" id="group-select">

                    <multiselect v-model="groupIds" :options="listGroup" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="--Select Group--" label="name" track-by="name" :preselect-first="true">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ groupIds.length }} Groups selected</span></template>
                    </multiselect>
                </div>
            </div>
            <div class="row">

                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Type</label>
                        <select name="applicationTypeId" id="applicationTypeId" v-model="applicationTypeId"  class="form-control" required>
                            <option value="">--Select Type--</option>
                            <option :value="item.id"  v-for="(item, index) in typeData" :key="index">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4">
                    <label>Start Date/Time</label>
                    <input type="text" class="form-control" v-model="starti" name="applicationStartDate" id="applicationStartDate" readonly>
                </div>
                <div class="col-4">
                    <label>End Date/Time</label>
                    <input type="text" class="form-control" v-model="endi" name="applicationEndDate" id="applicationEndDate" readonly>
                </div>

            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title (*)</label>
                        <input type="text" class="form-control"  name="name" v-model="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" v-model="explanation"  class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning" id="add-button"><i class="fa fa-plus"></i> ADD MEETING</button>
                </div>
            </div>
        </form>
    </b-modal>
    <b-modal id="modal-edit" size="lg" title="Edit Meeting" title-class="font-18" hide-footer>
        <form @submit.prevent="editData()" id="edit-group-form">
            <input type="hidden" name="id" v-model="recordId"/>
            <div class="row">
                <div class="col-12">
                    <i class="fa fa-info-circle"></i>  If you select this, you arrange a meeting for designated group if not you arrange a meeting with all groups.
                </div>
            </div>
            <div class="row form-group">
                <div class="col-sm-4">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" v-model="isInGroup"  value="" id="isInGroup" @change="isInGroupCheck($event)" >
                     <label class="form-check-label" for="isInGroup"> Group meeting?</label>
                   </div>
                </div>
                <div class="col-sm-8 d-none" id="group-select">
                    <multiselect v-model="groupIds" :options="listGroup" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="--Select Group--" label="name" track-by="name" :preselect-first="true">
                        <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length" v-show="!isOpen">{{ groupIds.length }} Groups selected</span></template>
                    </multiselect>
                </div>
            </div>
            <div class="row">

                <div class="col-sm-4">
                    <div class="form-group">
                        <label>Type</label>
                        <select name="applicationTypeId" id="applicationTypeId" v-model="applicationTypeId"  class="form-control">
                            <option value="">--Select Type--</option>
                            <option :value="item.id"  v-for="(item, index) in typeData" :key="index">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-4">
                    <label>Start Date/Time</label>
                    <date-picker v-model="starti" :first-day-of-week="1" lang="tr" name="applicationStartDate" type="datetime" value-type="DD.MM.YYYY HH:mm" 
                    format="DD.MM.YYYY HH:mm" :minute-step="15" :disabled-date="notBeforeToday"></date-picker>
                </div>
                <div class="col-4">
                    <label>End Date/Time</label>
                    <date-picker v-model="endi" :first-day-of-week="1" lang="tr" name="applicationEndDate" type="datetime" value-type="DD.MM.YYYY HH:mm" 
                    format="DD.MM.YYYY HH:mm" :minute-step="15" :disabled-date="notBeforeToday"></date-picker>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Title</label>
                        <input type="text" class="form-control"  name="name" v-model="name" autocomplete="off"/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" v-model="explanation"  class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning" id="edit-button"><i class="fa fa-check"></i> SAVE</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>
<style>
ul.inner-item{
    margin:0; padding: 0; list-style: none; text-align: left;
}
.tooltip-inner {
  background-color: initial !important;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #ff0000 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
 border-left-color: #ff0000 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
 border-bottom-color: #ff0000 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
 border-top-color: #000 !important;
}
</style>